// TODO fix paths https://github.com/ymolchanov/multiplication-trainer/issues/22
import { resetResults, save } from "./services/saveResult";
import {
  isCurrentSessionIdExists,
  uploadNewSessionIdToLocalStorage,
  clearSessionId,
} from "./services/session";
import {
  createDataPoolForTheSession,
  addAnswerToDataPool,
  getAllAnswersForCurrentSession,
} from "./services/dataPool";

import { render } from "./view/render";
import {
  exerciseSolved,
  getAllExercises,
  exerciseNotSolved,
  getFirstExercise,
} from "./model/exercise";
import { start } from "./services/timer";

let checkIsLate;
const yesButton = document.querySelector(".button_yes");
const noButton = document.querySelector(".button_no");
const resetButton = document.querySelector(".result-page__reset-button");

window.addEventListener("DOMContentLoaded", () => {
  if (!isCurrentSessionIdExists()) {
    const sessionId = uploadNewSessionIdToLocalStorage();
    createDataPoolForTheSession(sessionId);
  }

  const exercises = getAllExercises();

  render(exercises, getAllAnswersForCurrentSession());
  save(exercises);
  checkIsLate = start();
});

yesButton.addEventListener("click", () => {
  const isLate = checkIsLate();

  addAnswerToDataPool(getFirstExercise().multiplication, true, isLate);

  if (isLate) {
    exerciseNotSolved();
  } else {
    exerciseSolved();
  }

  const exercises = getAllExercises();

  render(exercises, getAllAnswersForCurrentSession());
  save(exercises);
  console.log(exercises.length);
});

noButton.addEventListener("click", () => {
  const isLate = checkIsLate();

  addAnswerToDataPool(getFirstExercise().multiplication, false, isLate);

  exerciseNotSolved();
  const exercises = getAllExercises();

  render(exercises, getAllAnswersForCurrentSession());
  save(exercises);
  console.log(exercises.length);
});

resetButton.addEventListener("click", () => {
  const response = window.confirm("Do you want to start training again?");
  if (response) {
    resetResults();
    clearSessionId();

    location.reload();
  }
});
