import { Exercise, DataPoolAnswer } from "../types";

import { showResultScreen } from "./showResultScreen";
import { showExercise } from "./showExercise";
import { clearRemaining, renderRemainingExercises } from "./remainingExercises";
import { renderTimerSetup, hideTimerSetup } from "./timerSetup";
import { startProgressBar, stopProgressBar } from "./progressBar";

export const render = (exercises: Exercise[], answers: DataPoolAnswer[]) => {
  const [exercise] = exercises;

  if (!exercise) {
    stopProgressBar();
    clearRemaining();
    hideTimerSetup();
    showResultScreen(answers);
    return;
  }

  startProgressBar();
  renderRemainingExercises(exercises);
  renderTimerSetup();
  showExercise(exercise);
};
