import { resetResults } from "../services/saveResult";
import { clearSessionId } from "../services/session";
import { Exercise } from "../types";

const remainingExerciseSelector = ".remaining";

const remainingNode = document.querySelector(remainingExerciseSelector);

remainingNode.addEventListener("click", () => {
  const response = window.confirm("Do you want to reset progress?");

  if (response) {
    resetResults();
    clearSessionId();
    location.reload();
  }
});

export const renderRemainingExercises = (exercises: Exercise[]) => {
  remainingNode.textContent = `Remains: ${exercises.length}`;
};

export const clearRemaining = () => {
  remainingNode.textContent = "";
};
