import { nanoid } from "nanoid";

const LOCALSTORAGE_SESSION_ID_KEY = "multiplication-trainer/sessionId";

export const getSessionId = () => {
  return localStorage.getItem(LOCALSTORAGE_SESSION_ID_KEY);
};

export const isCurrentSessionIdExists = () => {
  return Boolean(getSessionId()) ? true : false;
};

export const uploadNewSessionIdToLocalStorage = () => {
  const id = nanoid();

  localStorage.setItem(LOCALSTORAGE_SESSION_ID_KEY, id);

  return id;
};

export const clearSessionId = () => {
  localStorage.removeItem(LOCALSTORAGE_SESSION_ID_KEY);
};
