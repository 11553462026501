import { Exercise } from "../types";

export const ALL_POSIBLE_EXAMPLES: Exercise[] = [
  { multiplication: "2*2", answer: "4" },
  { multiplication: "2*3", answer: "6" },
  { multiplication: "2*4", answer: "8" },
  { multiplication: "2*5", answer: "10" },
  { multiplication: "2*6", answer: "12" },
  { multiplication: "2*7", answer: "14" },
  { multiplication: "2*8", answer: "16" },
  { multiplication: "2*9", answer: "18" },
  { multiplication: "3*2", answer: "6" },
  { multiplication: "3*3", answer: "9" },
  { multiplication: "3*4", answer: "12" },
  { multiplication: "3*5", answer: "15" },
  { multiplication: "3*6", answer: "18" },
  { multiplication: "3*7", answer: "21" },
  { multiplication: "3*8", answer: "24" },
  { multiplication: "3*9", answer: "27" },
  { multiplication: "4*2", answer: "8" },
  { multiplication: "4*3", answer: "12" },
  { multiplication: "4*4", answer: "16" },
  { multiplication: "4*5", answer: "20" },
  { multiplication: "4*6", answer: "24" },
  { multiplication: "4*7", answer: "28" },
  { multiplication: "4*8", answer: "32" },
  { multiplication: "4*9", answer: "36" },
  { multiplication: "5*2", answer: "10" },
  { multiplication: "5*3", answer: "15" },
  { multiplication: "5*4", answer: "20" },
  { multiplication: "5*5", answer: "25" },
  { multiplication: "5*6", answer: "30" },
  { multiplication: "5*7", answer: "35" },
  { multiplication: "5*8", answer: "40" },
  { multiplication: "5*9", answer: "45" },
  { multiplication: "6*2", answer: "12" },
  { multiplication: "6*3", answer: "18" },
  { multiplication: "6*4", answer: "24" },
  { multiplication: "6*5", answer: "30" },
  { multiplication: "6*6", answer: "36" },
  { multiplication: "6*7", answer: "42" },
  { multiplication: "6*8", answer: "48" },
  { multiplication: "6*9", answer: "54" },
  { multiplication: "7*2", answer: "14" },
  { multiplication: "7*3", answer: "21" },
  { multiplication: "7*4", answer: "28" },
  { multiplication: "7*5", answer: "35" },
  { multiplication: "7*6", answer: "42" },
  { multiplication: "7*7", answer: "49" },
  { multiplication: "7*8", answer: "56" },
  { multiplication: "7*9", answer: "63" },
  { multiplication: "8*2", answer: "16" },
  { multiplication: "8*3", answer: "24" },
  { multiplication: "8*4", answer: "32" },
  { multiplication: "8*5", answer: "40" },
  { multiplication: "8*6", answer: "48" },
  { multiplication: "8*7", answer: "56" },
  { multiplication: "8*8", answer: "64" },
  { multiplication: "8*9", answer: "72" },
  { multiplication: "9*2", answer: "18" },
  { multiplication: "9*3", answer: "27" },
  { multiplication: "9*4", answer: "36" },
  { multiplication: "9*5", answer: "45" },
  { multiplication: "9*6", answer: "54" },
  { multiplication: "9*7", answer: "63" },
  { multiplication: "9*8", answer: "72" },
  { multiplication: "9*9", answer: "81" },
];
