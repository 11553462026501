const KEY = "multiplication-trainer/timer-config";
const SECOND = 1000;

let isTimerStopped = false;
let timeoutId: number;

export const saveTimerConfig = (time: number): void => {
  localStorage.setItem(KEY, String(time));
  location.reload();
};

export const getTimerConfig = (): number => {
  const DEFAULT_TIME = 20;
  const time = localStorage.getItem(KEY);

  if (!time) {
    return DEFAULT_TIME;
  }

  return Number(time);
};

const time = SECOND * getTimerConfig();

export const start = () => {
  isTimerStopped = false;

  timeoutId = setTimeout(() => {
    isTimerStopped = true;
  }, time);

  return () => {
    const result = isTimerStopped;
    clearTimeout(timeoutId);
    start();

    return result;
  };
};
