import { getTimerConfig } from "../services/timer";

const time = getTimerConfig();

const setTimeToCss = (time?: number): void => {
  const duration = time ? `${time}s` : "unset";

  document
    .querySelector(".progress-bar")
    .setAttribute("style", `transition-duration: ${duration}`);
};

export const stopProgressBar = () => {
  setTimeToCss();
  document.querySelector(".progress-bar").classList.remove("progress-bar_run");
};

export const startProgressBar = () => {
  stopProgressBar();

  setTimeout(() => {
    setTimeToCss(time);
    document.querySelector(".progress-bar").classList.add("progress-bar_run");
  }, 50);
};
