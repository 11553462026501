import { DataPool } from "../types";
import { getSessionId } from "./session";

const LOCALSTORAGE_DATA_PULL_KEY = "multiplication-trainer/dataPool";

const EMPTY_DATA_POOL = {
  ids: [],
  entities: {},
};

const getDataPool = (): DataPool | null => {
  const dataPool = localStorage.getItem(LOCALSTORAGE_DATA_PULL_KEY);

  let result = null;

  try {
    result = JSON.parse(dataPool);
  } catch (e) {}

  return result;
};

/**
 * Return DataPool if exists or return a new empty DataPool
 */
const getCurrentOrCreateNewDataPool = (): DataPool => {
  const dataPool = getDataPool();

  if (!dataPool) {
    return EMPTY_DATA_POOL;
  }

  return dataPool;
};

const saveDatePool = (datePool: DataPool) => {
  const stringedDatePool = JSON.stringify(datePool);

  localStorage.setItem(LOCALSTORAGE_DATA_PULL_KEY, stringedDatePool);
};

export const createDataPoolForTheSession = (sessionId: string) => {
  const dataPool = getCurrentOrCreateNewDataPool();

  dataPool.ids.push(sessionId);
  dataPool.entities[sessionId] = {
    startSessionDate: new Date().toUTCString(),
    answers: [],
  };

  saveDatePool(dataPool);
};

export const addAnswerToDataPool = (
  exercise: string,
  isCorrect: boolean,
  isTimePassed: boolean
) => {
  const sessionId = getSessionId();
  const dataPool = getDataPool();

  if (!sessionId || !dataPool) {
    console.error("No sessionID or No DataPool!");
    return;
  }

  dataPool.entities[sessionId].answers.push({
    exercise,
    isCorrect,
    isTimePassed,
  });

  saveDatePool(dataPool);
};

export const getAllAnswersForCurrentSession = () => {
  const dataPool = getDataPool();
  const sessionId = getSessionId();

  return dataPool.entities[sessionId].answers;
};
