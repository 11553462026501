import { getTimerConfig, saveTimerConfig } from "../services/timer";

const time = getTimerConfig();
const timerSetupElement = document.querySelector(".timer-setup");

timerSetupElement.addEventListener("click", () => {
  const time = window.prompt("Set new timer duration in seconds (example: 20)");
  const timeAsNumber = Number.parseInt(time, 10);
  if (isNaN(timeAsNumber)) {
    return;
  }

  saveTimerConfig(timeAsNumber);
});

export const renderTimerSetup = () => {
  timerSetupElement.innerHTML = `Timer: ${time} seconds`;
};

export const hideTimerSetup = () => {
  timerSetupElement.innerHTML = "";
};
