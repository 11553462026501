import arrayShuffle from "array-shuffle";
import { ALL_POSIBLE_EXAMPLES } from "./constants";
import { Exercise } from "../types";
// TODO no services inside model https://github.com/ymolchanov/multiplication-trainer/issues/23
import { load } from "../services/saveResult";

const shuffleExercises = (exercises: Exercise[]) => arrayShuffle(exercises);

const generateExercises = () => shuffleExercises(ALL_POSIBLE_EXAMPLES);

export const exerciseSolved = () => {
  exercises.shift();
};

export const exerciseNotSolved = () => {
  const exercise = exercises.shift();

  exercises.push(exercise);
};

export const getFirstExercise = (): Exercise | undefined => exercises[0];

export const getAllExercises = (): Exercise[] => exercises;

const createExercises = () => {
  const storedItems = load();

  if (storedItems) {
    return storedItems;
  }

  return generateExercises();
};

export const exercises = createExercises();
