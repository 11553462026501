import { DataPoolAnswer } from "../types";

interface ClassifiedAnswers {
  [key: DataPoolAnswer["exercise"]]: number;
}

const classifyItems = (answers: DataPoolAnswer[]): ClassifiedAnswers => {
  return answers.reduce((acc, item) => {
    const { exercise } = item;

    if (!acc[exercise]) {
      acc[exercise] = 0;
    }

    acc[exercise] = acc[exercise] + 1;

    return acc;
  }, {});
};

const renderItems = (answers: ClassifiedAnswers) => {
  const fragment = new DocumentFragment();

  Object.keys(answers).map((exersise) => {
    const li = document.createElement("li");
    li.innerHTML = `${exersise} ------- ${answers[exersise]}`;

    return fragment.appendChild(li);
  });

  return fragment;
};

export const showResultScreen = (answers: DataPoolAnswer[]) => {
  const wrongItems = classifyItems(
    answers.filter((answer) => !answer.isCorrect)
  );
  const notInTimeItems = classifyItems(
    answers.filter((answer) => answer.isTimePassed && answer.isCorrect)
  );

  document
    .querySelector(".result-page__list_wrong")
    .appendChild(renderItems(wrongItems));

  document
    .querySelector(".result-page__list_time-passed")
    .appendChild(renderItems(notInTimeItems));

  if (
    Object.keys(wrongItems).length + Object.keys(notInTimeItems).length ===
    0
  ) {
    document
      .querySelector(".result-page__list-wrapper")
      .classList.add("hidden");
    document
      .querySelector(".result-page__wow-image")
      .classList.remove("hidden");
    document.querySelector(".result-page__wow-text").classList.remove("hidden");
  }

  document.querySelector(".result-page").classList.remove("hidden");
  document.querySelector(".exercise-page").classList.add("hidden");
};
