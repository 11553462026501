const KEY = "multiplication-trainer/storage";

export const save = (item) => {
  const stringifiedItem = JSON.stringify(item);

  localStorage.setItem(KEY, stringifiedItem);
};

export const load = () => {
  const stringifiedItem = localStorage.getItem(KEY);

  return JSON.parse(stringifiedItem);
};

export const resetResults = () => {
  localStorage.removeItem(KEY);
};
